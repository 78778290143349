import '../styles/Home.css'
import { Link } from "react-router-dom";

function Home() {
    return (
        <div className="Home">
            <header className="Home-header">
                <h1>Kyle Eckenstine</h1>
                <p>Software Engineer, Cooking Enthusiast</p>
            </header>
            <div className="Home-body">
                <nav>
                    <Link to="reviews">Restaurant Reviews</Link>
                </nav>

                <h2>Welcome to eckenstine.com!</h2>

                <p className="About-p">
                    My name is Kyle Eckenstine. This is my website, it's currently a work in progress but I plan to continuously update it. I'm
                    working on creating a food blog, and a cooking app, both currently WIPs. You'll find links to my GitHub and LinkedIn at the bottom
                    of the site as well as my email. Feel free to reach out to me!
                </p>
            </div>
            <footer className="Home-footer">
                <div className="Links-div">
                    <a className="Home-link" href="https://github.com/eckenstine" target="_blank" rel="noopener noreferrer">
                        GitHub
                    </a>
                    <a className="Home-link" href="https://www.linkedin.com/in/eckenstine/" target="_blank" rel="noopener noreferrer">
                        LinkedIn
                    </a>
                    <a className="Home-link" href="mailto:eckenstine@gmail.com">
                        Email
                    </a>
                </div>
            </footer>
        </div>
    )
}

export default Home
