import Home from './components/Home'
import ReviewComp from './components/ReviewComp'
import {
    BrowserRouter as Router,
    Routes,
    Route
} from "react-router-dom"

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="reviews" element={<ReviewComp />}></Route>
            </Routes>
        </Router>
    );
}

export default App
