import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore'

const firebaseConfig = {
    apiKey: "AIzaSyAUAZ7sAI4F9PR1pVjjgGfo-0SE2ISJ2mA",
    authDomain: "eckenstine-35261.firebaseapp.com",
    projectId: "eckenstine-35261",
    storageBucket: "eckenstine-35261.appspot.com",
    messagingSenderId: "258845341652",
    appId: "1:258845341652:web:5cbe5136d3a922c8614841",
    measurementId: "G-7PWZ74R60Y"
}

const firebaseApp = firebase.initializeApp(firebaseConfig)
const db = firebase.firestore()

export default db