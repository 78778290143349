import { Link } from 'react-router-dom'

function ReviewComp() {
    return (
        <div>
            <h1>Restaurant Reviews</h1>
            <nav>
                <Link to="/">Home</Link>
            </nav>
        </div >
    )
}

export default ReviewComp